import * as singleSpa from 'single-spa';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import eventBus from './event-bus';
import { showWhenPrefix, load } from './utils';

System.import('@iris/environment').then(m => m.default).then(environment => {
  window.environment = environment;
  const isProduction = /true/g.test(environment.production);
  const isTesting = /true/g.test(environment.testing);

  // disable environment log in Prod/Staging
  if (!isProduction || isTesting) {
    console.log(`Dynamic config: ${JSON.stringify(environment)}`);
  }

  singleSpa.registerApplication(
    'admin-host-app',
    () => load('@iris/admin-host-app'),
    showWhenPrefix(['/']),
    { environment, eventBus },
  );

  singleSpa.registerApplication(
    'communications-admin-component-pkg',
    () => load('@iris/communications-admin-component-pkg'),
    showWhenPrefix(['/admin/communications']),
    { environment, eventBus },
  );


  //Removed until the time it is back on the backlog
//singleSpa.registerApplication(
//    'accounts-production-admin-component-pkg',
//    () => load('@iris/accountsproduction-admin-component-v01-pkg'),
//    showWhenPrefix(['/admin/accounts-production']),
//    { environment, eventBus },
//  );

  singleSpa.start();
});
